import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import cx from 'classnames';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Navigation } from '.';
import config from '../../utils/siteConfig';

import {HamburgerButton, Search} from './../components';

// Styles
import '../../styles/app.css';

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/

const DefaultLayout = ({ data, children, bodyClass, isHome, activePage, noShadow, green }) => {
    const coverImage = isHome ? true : false;
    const showLogo = isHome ? false : true;
    const site = data.allGhostSettings.edges[0].node;
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null;
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null;
    const scrollHeaderElem = useRef(null);
    const [isNavActive, setIsNavActive] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isScrolling, setIsScrolling] = useState(showLogo);
    const [showCoverImage, setShowCoverImage] = useState(coverImage);
		const iconData = green ? data.greenIcon.childImageSharp.fixed : data.icon.childImageSharp.fixed;

    noShadow = !isHome ? noShadow || false : false;
    green = green || false;

    let lastScrollY = 0;
    let scrolling = false;

    function inViewport(pos) {
      return (pos.top <= window.innerHeight && pos.top > 0)
        || (pos.bottom >= 0 && pos.bottom <= window.innerHeight);
    }

    function lazyLoad(el) {
        if(el.tagName == 'IMG') {
            el.src = el.getAttribute('data-src');
        }
        else {
            el.style.backroundImage = `url(${el.getAttribute('data-src')})`;
        }
        el.classList.remove('lazy');
    }

    function onScrollLazyHandler(e) {
        const lazyEls = document.querySelectorAll('.lazy');
        for(let i = 0; i < lazyEls.length; i++) {
            let el = lazyEls[i];
            let pos = el.getBoundingClientRect();
            if(inViewport(pos)) {
                lazyLoad(el);
            }
        }
    }

    const onResize = () => {
        if(window.innerWidth <= 1030) {
            setIsScrolling(true);
            setShowCoverImage(false);
        }
        else {
            setIsScrolling(false);
            setShowCoverImage(true);
        }
    }

    function onScrollNavHandler(e) {
        const scrollY = window.scrollY;
        const header = scrollHeaderElem.current;

        if(header == null) {
            return;
        }

        if(scrollY > 0) {
            if(!header.classList.contains('scroll')) {
                header.classList.add('scroll');

                if(window.innerWidth > 1030 && isHome)
                    setTimeout(() => setIsScrolling(true), 300);
            }
            if(lastScrollY < scrollY && header.classList.contains('show')) {
                header.classList.remove('show');
                scrolling = true;
            }
            else if(lastScrollY > scrollY && !header.classList.contains('show')) {
                header.classList.add('show');
                scrolling = false;
            }

            lastScrollY = scrollY;
        }
        else if(scrollY <= 0) {
            header.classList.remove('scroll');
            header.classList.remove('show');
            if(window.innerWidth > 1030  && isHome)
                setIsScrolling(false);
        }
    }

    function toggleNav(active) {
        if(active && isSearchActive && window.innerWidth <= 768) setIsSearchActive(false);
        setIsNavActive(active);
    }

    function toggleSearch(active) {
        if(active && isNavActive && window.innerWidth <= 768) setIsNavActive(false);
        setIsSearchActive(active)
    }

    useEffect(() => {
        onScrollLazyHandler();
        window.addEventListener('scroll', onScrollLazyHandler, {passive: true});
        window.addEventListener('scroll', onScrollNavHandler, {passive: true});

        if(window.innerWidth <= 1030 || !isHome) {
            setIsScrolling(true);
            setShowCoverImage(false);
        }

        if(isHome) {
            window.addEventListener('resize', onResize, {passive: true});
        }

        return () => {
            window.removeEventListener('scroll', onScrollLazyHandler);
            window.addEventListener('scroll', onScrollNavHandler, {passive: true});

            if(isHome) {
                window.removeEventListener('resize', onResize);
            }
        }
    });

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <style type="text/css">
                {`#___gatsby, #gatsby-focus-wrapper{overflow: hidden;}/*fucking IE...*@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.viewport-top {min-height: calc(100vh - 129px);}.viewport-bottom {height:89px;margin-top:40px;}.site-main{position:relative;top:30px;}}`}
                </style>
                <body className={bodyClass} />
            </Helmet>
            <div className={cx('viewport', {'green': green}, {'active-menu': isNavActive})}>
                <div className='viewport-top'>
                    {/* The main header section on top of the screen */}
                    <header className={cx('site-head', {'no-shadow': noShadow})}>
                        <div className="site-nav-container">
                            <div ref={scrollHeaderElem} className={cx('nav-container')}>
                                <div className="container">
                                    <div className="site-mast">
                                        <div className="site-mast-left">
                                                {isScrolling ?
                                                  <Link to="/">
                                                      <Img fixed={iconData} style={{width: 90, height: 90, position: 'absolute', top: -5}} alt={site.title} />
                                                  </Link>
                                                : null }
                                        </div>
                                        <nav className={isNavActive ? 'site-nav active-menu' : 'site-nav'}>
                                            <div className="site-nav-left" style={{zIndex: 1}}>
                                                {/* The navigation items as setup in Ghost */}
                                                <Navigation
                                                    data={site.navigation}
                                                    navClass="site-nav-item"
                                                    activePage={activePage} />
                                            </div>
                                            {/*<div className="site-nav-right">
                                                <Link className="site-nav-button" to="/">Blog</Link>
                                            </div>*/}
                                        </nav>
                                        <div className="site-mast-right">
                                            { site.twitter && <a href={ twitterUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/twitter.svg" alt="Twitter" /></a>}
                                            { site.facebook && <a href={ facebookUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/facebook.svg" alt="Facebook" /></a>}
                                            <Search active={isSearchActive} scrolling={scrolling} onClick={toggleSearch} />
                                            <HamburgerButton active={isNavActive} onClick={toggleNav} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            { isHome ?
                              showCoverImage &&
                                <div className="site-banner">
                                    <Img fixed={data.largeIcon.childImageSharp.fixed} style={{width: 256, height: 256, marginTop: -40}} alt={site.title} />
                                    <p className="site-banner-desc" style={{marginTop: -35}}>
										<span>
											<span class="mtk3 mtkb">const</span>
											<span class="mtk1">&nbsp;</span>
											<span class="mtk8">dev</span>
											<span class="mtk1">&nbsp;</span>
											<span class="mtk9">=</span>
											<span class="mtk1">&nbsp;</span>
											<span class="mtk1 ced-1-TextEditorDecorationType13-1 ced-1-TextEditorDecorationType56-1">(</span>
											<span class="mtk8">app</span>
											<span class="mtk1">,&nbsp;</span>
											<span class="mtk8">web</span>
											<span class="mtk1 ced-1-TextEditorDecorationType13-1 ced-1-TextEditorDecorationType56-1">)</span>
											<span class="mtk1">&nbsp;</span>
											<span class="mtk3 mtkb">=&gt;</span>
											<span class="mtk1">&nbsp;</span>
											<span class="mtk11">true</span>
										</span>
									</p>
                                </div>
                               :
                                null }
                        </div>
                    </header>

                    <main className="site-main">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>

                </div>

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                <Link to="/">{site.title}</Link> © 2020 - {new Date().getFullYear()}
                            </div>
                            <div className="site-foot-nav-right">
                                <Navigation data={site.secondary_navigation} activePage={activePage} navClass="site-foot-nav-item" />
                            </div>
                        </div>
                    </footer>

                </div>
            </div>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        icon: PropTypes.object,
        largeIcon: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                icon: file(relativePath: {eq: "mask-xl.png"}) {
                    childImageSharp {
                        fixed(width: 90, height: 90, quality: 75) {
                          ...GatsbyImageSharpFixed
                        }
                    }
                }
								greenIcon: file(relativePath: {eq: "mask-green-xl.png"}) {
										childImageSharp {
                        fixed(width: 90, height: 90, quality: 75) {
                          ...GatsbyImageSharpFixed
                        }
                    }

								}
                largeIcon: file(relativePath: {eq: "mask-xl.png"}) {
                      childImageSharp {
                        fixed(width: 256, height: 256, quality: 75) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                  }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
