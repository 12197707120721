import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const HamburgerButton = ({active, onClick}) => {

    const handleClick = (e) => {
        onClick(!active);
    }

    return (
        <div className={active ? 'hamburg active' : 'hamburg'} onClick={handleClick} >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>            
        </div>
    )
}

export default HamburgerButton;
