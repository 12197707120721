import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from "gatsby-image";
import Helmet from 'react-helmet';
import cx from 'classnames';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const Page = ({ data, location, pageContext }) => {
    const page = data.ghostPage;
    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="website"
            />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout
                activePage={page.title}
                noShadow={true}
                green={pageContext.green}>

                <div className="container">
                    <article className="content">
                        {(pageContext.image && !pageContext.video) &&
                        <>
                            <header className={cx('fancy', 'feature-image-shadow')}>
                                <figure className='fancy-feature-image'>
                                    {pageContext.image && <Image fluid={data.file.childImageSharp.fluid} alt={page.title}/>}
                                </figure>
                                <h1 className="content-title">{page.title}</h1>
                            </header>
                        </>}
                        {((!pageContext.image && page.feature_image) || pageContext.video) &&
                        <>
                           <figure className={cx('post-feature-image', 'feature-image-shadow')}>
                                {(!pageContext.image && page.feature_image && !pageContext.video) &&
                               <Image fluid={page.localImage.childImageSharp.fluid} alt={page.title}/>}
                               {pageContext.video &&
                                <>
                                    <video loop={true} autoPlay={true} src={`/movies/${pageContext.video}`} style={{width:'100%'}}></video>
                                </>
                               }
                           </figure>
                        </>}
                        <section className={cx('post-full-content', 'page')}>
                            {!pageContext.image && <h1 className="content-title">{page.title}</h1>}
                            {/* The main page content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: pageContext.html }}
                            />
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
        image: PropTypes.string,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Page

export const postQuery = graphql`
    query ($slug: String, $image: String) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
            localImage {
                childImageSharp {
                    fluid(maxHeight: 600, maxWidth: 1920, fit: COVER, cropFocus: CENTER, toFormat: JPG, quality: 90)  {
                        ...GatsbyImageSharpFluid_withWebp
                        }
                    }

            }
        }
        file(relativePath: {eq: $image}) {
          childImageSharp {
            fluid(fit: COVER, maxHeight: 1024, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
    }`;
