import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Tags } from '@tryghost/helpers-gatsby';
import { readingTime as readingTimeHelper } from '@tryghost/helpers';

const PostCard = ({ post, featured }) => {
    const url = `/blog/${post.primary_tag.slug}/${post.slug}/`;
    const readingTime = readingTimeHelper(post, {minutes: `% Minuten Lesezeit`, minute: `1 Minute Lesezeit`, seconds: 'Weniger als 1 Minute Lesezeit'});

    return (

        <Link to={url} className={post.featured || featured ? 'post-card large' : 'post-card'} >
            {post.feature_image &&
            <figure className="post-card-figure">
                <Image
                  className="post-card-image"
                  fluid={{
                    ...post.localImage.childImageSharp.fluid,
                    sizes: post.fields.sizes
                  }}
                  alt={post.title} />
            </figure>
            }
            <section className="post-card-content">
                <header className="post-card-header">
                    {post.tags && <div className="post-card-tags">{post.primary_tag.name}</div>}
                    <h2 className="post-card-title">{post.title}</h2>
                </header>
                <section className="post-card-excerpt">{post.excerpt}</section>
                <footer className="post-card-footer">
                    <div className="post-card-footer-left">
                        <div className="post-card-date">
                            <span>{ post.published_at_de }</span>
                        </div>
                    </div>
                    <div className="post-card-footer-right">
                        <div>{readingTime}</div>
                    </div>
                </footer>
            </section>
        </Link>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        primary_tag: PropTypes.shape({
            name: PropTypes.string
        }),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
