/* eslint-disable */
/**
 * Trust All Scripts
 *
 * This is a dirty little script for iterating over script tags
 * of your Ghost posts and adding them to the document head.
 *
 * This works for any script that then injects content into the page
 * via ids/classnames etc.
 *
 */
require('prismjs/themes/prism-coy.css');
//require('highlight.js/styles/default.css');

let entry = true;

const trustAllScripts = () => {
    const scriptNodes = document.querySelectorAll('.load-external-scripts script');

    for (let i = 0; i < scriptNodes.length; i += 1) {
        const node = scriptNodes[i];
        const s = document.createElement('script');
        s.type = node.type || 'text/javascript';

        if (node.attributes.src) {
            s.src = node.attributes.src.value;
        } else {
            s.innerHTML = node.innerHTML;
        }

        document.getElementsByTagName('head')[0].appendChild(s);
    }
};

exports.onRouteUpdate = async () => {
    if(window.innerWidth <= 768 ) {
        document.querySelector('html').classList.add('mobile');
    }

    trustAllScripts();
}

exports.onClientEntry = () => {
    if(window.ResizeObserver) {
        new ResizeObserver(entries => {
           const {width} = entries[0].contentRect;
           if(width <= 768) {
               entries[0].target.classList.add('mobile');
           }
           else {
               entries[0].target.classList.remove('mobile');
           }
       }).observe(document.querySelector('html'));
    }

    if(top != self) top.location = self.location;
}
