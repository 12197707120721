module.exports = {
    siteUrl: `https://michm.de`, // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `michm.de`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Hallo ich bin Manne und schreibe hier über Softwarelösungen für Probleme, die ich mir oft selbst mache. ~addicted to programming since 1994~`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1200, // Change to the width of your default share image
    shareImageHeight: 600, // Change to the height of your default share image

    shortTitle: `michm.de`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `static/icon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#16324e`, // Used for Offline Manifest
}
