import React from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { Navigation } from '../components/common';
import Img from 'gatsby-image';

const UnsupportedPage = ({data, file}) => {
    return (
    <>
        <Helmet>
            <html lang="de" />
            <title>Fehlende Unterstützung des Browsers - MichM</title>
            <style type="text/css">
            {`#___gatsby, #gatsby-focus-wrapper{overflow: hidden;}/*fucking IE...*/@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.viewport-top {min-height: calc(100vh - 129px);}.viewport-bottom {height:89px;margin-top:40px;}.site-main{position:relative;top:30px;}}`}
            </style>
        </Helmet>
        <div className="viewport">
            <div className="viewport-top">
                <div className="container">
                    <main>
                        <article className="content" style={{ textAlign: `center` }}>
                            <Img fluid={data.file.childImageSharp.fluid} alt='logo' />

                            <h1 className="content-title">Browser wird nicht unterstützt</h1>
                            <section className="content-body">
                                <p>Der Browser den Sie verwenden ist stark veraltet und unterstützt längst standadisierte Features nicht.
                                Auch Ihrer Sicherheit zuliebe sollten Sie den Browser aktualisieren.</p>
                                <p>Eine Auswahl an geeigneten Browsern finden Sie bei <a href="https://browsehappy.com/?locale=de_DE" rel="noopener noreferrer">browsehappy.com</a>.</p>
                            </section>
                        </article>
                    </main>
                </div>
            </div>
        </div>
    </>
        )
}

UnsupportedPage.propTypes = {
  data: PropTypes.shape({
      file: PropTypes.object
  }).isRequired
}

const UnsupportedQuery= props => (
    <StaticQuery
        query={graphql`
            query Logo {
                file(relativePath: {eq: "glasses-xl.png"}) {
                  childImageSharp {
                    fluid(fit: COVER) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
            }
        `}
        render={data => <UnsupportedPage data={data} {...props} />}
    />
)

export default UnsupportedQuery
