import React, {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {MdSearch, MdClose} from 'react-icons/md';
//import SearchWorker from '../../utils/search.worker.js';

import './style.css';

const Search = ({active, scrolling, onClick, maxResults = 10}) => {
    const [result, setResult] = useState([]);
    const [style, setStyle] = useState({});
    const containerElement = useRef(null);
    const inputElement = useRef(null);
    const searchWorkerRef = useRef(null);

    let lastScrollY = 0;
    /*
    function getSearchWorker() {
        if(!searchWorkerRef.current) {
          searchWorkerRef.current = new SearchWorker();
        }
        return searchWorkerRef.current;
    }
    */
    function close(e) {
        const elem = e.srcElement;
        const input = containerElement.current.querySelector('#input-container');
        const button = containerElement.current.querySelector('button#find');
        const overlay = containerElement.current.querySelector('.overlay');
        const html = document.querySelector('html');

        if(elem.id == 'input-container' || elem.tagName == 'FORM'
        || elem.tagName == 'INPUT' || elem.tagName == 'SVG'
        || elem.tagName == 'BUTTON' || elem.tagName == 'PATH') {
            return;
        }

        setResult([]);
        overlay.removeEventListener('click', close);
        button.classList.remove('active');
        input.style.opacity = 0;
        input.style.transform = '';
        overlay.style.opacity = 0;
        html.style.overflow = '';
        setTimeout(() => {
            input.style.display = '';
            input.style.transition = '';
            overlay.style.display = '';
            overlay.style.transition = '';
            if(inputElement.current !== null)
                inputElement.current.value = '';
        }, 300);

        onClick(!active);
    }

    function open(e) {
        const input = containerElement.current.querySelector('#input-container');
        const button = containerElement.current.querySelector('button#find');
        const overlay = containerElement.current.querySelector('.overlay');
        const inputEl = inputElement.current;

        if(!active) {
            input.style.display = 'inline-block';
            button.classList.add('active');
            overlay.style.display = 'block';
            setTimeout(() => {
                input.style.opacity = 1;
                input.style.transform = 'translateX(0px)';
                overlay.style.opacity = .5;
                setTimeout(() => {
                    inputEl.focus();
                    overlay.style.transition = 'all .3s ease';
                    input.style.transition = 'all .2s ease';
                }, 200)
            }, 25);
        }
        else {
            close(e);
            return;
        }

        onClick(!active);
    }

    async function change() {
        try {
            const query = encodeURIComponent(inputElement.current.value.toLowerCase());

            if(query.length > 1) {
                //const results = await getSearchWorker().search(query, maxResults);
                const res = await fetch(`https://michm.de/suche?q=${query}`);
                const results = await res.json();

                if(results !== undefined && typeof results == 'object') {
                    setResult(Object.entries(results));
                }
                else {
                    setResult([]);
                }
            }
            else {
                setResult([]);
            }
        }
        catch(error) {
            console.error(error.message);
        }
    }

    function onScroll(e) {
        const scrollY = window.scrollY;

        if(window.innerWidth <= 768) {
            return;
        }

        if(scrollY > 0) {
            if(lastScrollY < scrollY && active) {
                setResult([]);
                if(inputElement.current !== null)
                    inputElement.current.value = '';
            }

            lastScrollY = scrollY;
        }
    }

    useEffect(() => {
        const buttonOpen = containerElement.current.querySelector('button#find');
        const buttonClose = containerElement.current.querySelector('button#close');
        buttonOpen.addEventListener('click', open);
        buttonClose.addEventListener('click', close);

        return () => {
            buttonOpen.removeEventListener('click', open);
            buttonClose.removeEventListener('click', close);
        }
    })

    useEffect(() => {
        if(active) {
            const overlay = containerElement.current.querySelector('.overlay');
            const buttonOpen = containerElement.current.querySelector('button#find');
            const buttonClose = containerElement.current.querySelector('button#close');

            overlay.addEventListener('click', close);
            buttonOpen.addEventListener('click', open);
            buttonClose.addEventListener('click', close);

            return () => {
                overlay.removeEventListener('click', close);
                buttonOpen.removeEventListener('click', open);
                buttonClose.removeEventListener('click', close);
            }
        }
    });

    useEffect(() => {
        window.addEventListener('scroll', onScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    })

    return (
        <>
            <div ref={containerElement} id="search-container">
                <div id="input-container"
                    style={result.length ? {borderBottomLeftRadius: 0,borderBottomRightRadius: 0} : {}}
                    >
                    <input
                        type="text"
                        ref={inputElement}
                        onChange={change}
                        placeholder="Suchen..." />

                    <button id="close" aria-label="close"><MdClose className="icon" /></button>
                </div>
                <div className="overlay"></div>
                {result.length > 0 &&
                    <div className="result-container" style={style}>
                        <ul>
                            {result.map(([slug, title], i) => {
                                return (<li key={i}><Link to={`/${slug}`}>{title}</Link></li>)
                            })}
                        </ul>
                    </div>
                }
                <button id="find" aria-label="search"><MdSearch className="icon" /></button>
            </div>
        </>
    )
}

Search.propTypes = {
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    maxResults: PropTypes.number
}

export default Search;
