import React, {useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Image from "gatsby-image";
import cx from 'classnames';
import Helmet from 'react-helmet';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
// import hljsWorker from '../utils/hljsWorker.js';

import 'prismjs';
// import 'highlight.js/styles/github.css';
// import 'prismjs/themes/prism-coy.css';

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location, pageContext }) => {
  const containerRef = useRef();
  const post = data.ghostPost;
  const category = post.tags.find((tag) => tag.name == 'JavaScript') ? 'JavaScript' : 'Blog';

  useEffect(() => {   
    const mobile = document?.querySelector('html')?.classList.contains('mobile');
    
    if (!mobile)
      Prism.highlightAll();   
  });

    return (
      <>
        <MetaData
          data={data}
          location={location}
          type="article"
        />
        <Helmet>
          <style type="text/css">{`${post.codeinjection_styles}`}</style>
        </Helmet>
        <Layout activePage='Blog' noShadow={true} green={pageContext.green}>
          <div className="container">
              <article className="content">
              { post.feature_image ?
                <figure className={cx('post-feature-image', 'feature-image-shadow')}>
                    <Image fluid={post.localImage.childImageSharp.fluid} alt={post.title}/>
                </figure> : null }
                <header className="post-full-header">
                  <section className="tag-nav">
                    {post.tags.map((item, i) => {
                        return (
                          <Link to={`/blog/${item.slug}`} key={i}>
                              {item.name}
                          </Link>
                        )
                    })}
                  </section>
                  <h1 className="content-title">{post.title}</h1>
                  <p className="post-full-custom-excerpt">
                    {post.excerpt}
                  </p>
                </header>
                <section className={cx('post-full-content', 'blog')}>
                  {/* The main post content */ }
                  <section
                      ref={containerRef}
                      className="content-body load-external-scripts"
                      dangerouslySetInnerHTML={{ __html: pageContext.html }}
                  />
                </section>
            </article>
          </div>
        </Layout>
    </>
  )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
        ...GhostPostFields
        localImage {
            childImageSharp {
                fluid(maxHeight: 600, maxWidth: 1920, fit: COVER, cropFocus: CENTER, toFormat: JPG, quality: 90)  {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
}`;
