import React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../components/common';

const NotFoundPage = () => (
    <Layout>
        <div className="container">
            <article className="content" style={{ textAlign: `center` }}>
                <h1 className="content-title">¯\(°_o)/¯</h1>
                <section className="content-body">
                    <h2>Fehler 404</h2>
                    <p>Die Seite wurde nicht gefunden.</p>
                    <p>Möchten Sie <Link to="/">zur Startseite</Link> zurück?</p>
                </section>
            </article>
        </div>
    </Layout>
)

export default NotFoundPage;
