import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout, PostCard, Pagination } from '../components/common';
import { MetaData } from '../components/common/meta';

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
    let posts = data.allGhostPost.edges;
    let featuredCount = 0;

    posts.forEach((post, index) => {
        if(post.node.featured) {
            featuredCount++;
            if(index == 1) {
                posts[0].node.featured = true;
            }

            if(index == posts.length - 2) {
                posts[index].node.featured = true;
            }
        }
    })
    const count = posts.length - featuredCount;
    const odd = count % 2;

    return (
        <>
            <MetaData location={location} image="/images/share.jpg" type="website" title="Blog" />
            <Layout activePage="Blog" isHome={true}>
                <div className="container">
                    <section className={odd ? 'post-feed odd' : 'post-feed'}>
                        {posts.filter(p => p.node.tags !== "#hidden").map(({ node }) => (
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
        breakpoints: PropTypes.array,
        sizes: PropTypes.string
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery(
    $limit: Int!,
    $skip: Int!,
    $breakpoints: [Int] = [1200, 980, 680, 420, 320],
  ) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
          published_at_de: published_at(formatString: "DD.MM.YYYY")
          fields {
            sizes
          }
          localImage {
            childImageSharp {
              fluid(
                maxWidth: 750,
                srcSetBreakpoints: $breakpoints,
                fit: COVER,
                cropFocus: CENTER,
                toFormat: JPG,
                quality: 90) {
                ...GatsbyImageSharpFluid_withWebp,
              }
            }
          }
        }
      }
    }
  }
`
