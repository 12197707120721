module.exports = [{
      plugin: require('../plugins/gatsby-plugin-search/gatsby-browser.js'),
      options: {"plugins":[],"redis":{"host":"localhost","port":6379},"nodes":[{"node_type":"GhostPage","slug_field":"slug","title_field":"title","id":"id","fields":["plaintext","title","excerpt"]},{"node_type":"GhostPost","slug_field":"slug","title_field":"title","id":"id","fields":["plaintext","title","excerpt"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"michaelis_m - Blog","short_name":"michm.de","start_url":"/","background_color":"#e9e9e9","theme_color":"#16324e","display":"standalone","cache_busting_mode":"none","icon":"static/icon.png","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
